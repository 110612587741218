<template>
  <el-drawer title="测评结果评估" :visible="visible" size="40%">
    <div class="component-container">
      <el-form
        ref="dataForm"
        :model="dataForm"
        label-width="120px"
        :rules="dataFormRule"
      >
        <el-form-item label="测试编号">
          {{ data.Code }}
        </el-form-item>
        <el-form-item label="姓名">
          <span v-if="data.Member">{{ data.Member.Name }}</span>
        </el-form-item>
        <el-form-item label="评估结果">
          <el-input
            type="textarea"
            v-model="dataForm.summary"
            :rows="6"
            placeholder="请输入评价结果"
          />
        </el-form-item>
        <el-form-item label="训练建议">
          <el-input
            type="textarea"
            :rows="6"
            v-model="dataForm.suggest"
            placeholder="请输入训练建议"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="visible = false">取消</el-button>
          <el-button type="success" @click="submit">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
</template>
<script>
import { evaulate } from "@/api/check/check.js";
export default {
  data() {
    return {
      visible: false,
      data: "",
      dataForm: {
        key: "",
        summary: "",
        suggest: "",
      },
      dataFormRule: {
        summary: [
          { required: true, message: "请输入评估结果", trigger: "change" },
        ],
        suggest: [
          { required: true, message: "请输入训练建议", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    show(row) {
      this.data = row;
      this.dataForm = {
        key: row.Key,
        summary: "",
        suggest: "",
      };
      this.visible = true;
    },
    submit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          evaulate(this.dataForm).then((res) => {
            this.visible = false;
            this.$emit("update");
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.component-container {
  padding: 20px;
  box-sizing: border-box;
}
.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>